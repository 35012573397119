import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import './App.css'; // Tło i grafiki można dodać w tym pliku CSS

const App = () => {
  return (
    <Box 
    sx={{
      backgroundImage: `url(${process.env.PUBLIC_URL + '/background.png'})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: 'white',
      position: 'relative'
    }}
  >
    {/* Zakładki na górze */}
    <Box 
      sx={{
        position: 'absolute',
        top: '50px', // Umieszczamy zakładki na górze
        width: '100%', // Zakładki zajmują pełną szerokość
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid 
        container 
        spacing={2} 
        justifyContent="center" 
        sx={{ maxWidth: '1200px', padding: '0 20px' }}
      >
        {[
          { text: 'Zestawy bezalkoholowe', link: '/katalog.pdf#page=3' },
          { text: 'Zestawy z winem', link: '/katalog.pdf#page=12' },
          { text: 'Zestawy z alkoholem', link: '/katalog.pdf#page=25' },
          { text: 'Personalizacja', link: '/katalog.pdf#page=35' }
        ].map((item) => (
          <Grid 
            key={item.text} 
            item 
            xs={12}  // Dla małych ekranów (mobile), elementy będą jeden pod drugim
            sm={6}   // Dla średnich ekranów (tablety) dwa w rzędzie
            md={3}   // Dla dużych ekranów (desktopy) będą cztery w jednym rzędzie
          >
            <a 
              href={item.link} 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                textDecoration: 'none', 
                color: '#ffffff', 
                fontSize: '1rem',
                justifyContent: 'center',
                fontSize: '1rem',
                letterSpacinWg: '2px',
                fontWeight: '400',
                textTransform: 'uppercase'
              }}
            >
              <Box
                sx={{
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  backgroundColor: '#bd9461',
                  marginRight: '10px'
                }}
              />
              {item.text}
            </a>
          </Grid>
        ))}
      </Grid>
    </Box>

      <Box sx={{ maxWidth: '1200px', padding: '20px' }}>
        {/* Główne Logo */}
        <img 
          src={`${process.env.PUBLIC_URL + '/logo.png'}`} 
          alt="Świąteczne zestawy prezentowe"
          style={{ maxWidth: '100%', height: 'auto', marginBottom: '30px' }}
        />
        
        {/* Opis */}
        <br/>
          Z ogromną przyjemnością przedstawiamy katalog prezentów świątecznych rok 2024.
          W naszym katalogu znajdą Państwo szeroki wybór zestawów delikatesowych, z winem i mocniejszym alkoholem,
          które będą idealnym prezentem dla najbliższych, pracowników i partnerów biznesowych.
       <br/>

        {/* Katalog Button */}
        <a href={`${process.env.PUBLIC_URL + '/katalog.pdf'}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

        <Button 
          variant="contained" 
          className='poppins-semibold'
          color="primary" 
          sx={{ 
            backgroundColor: '#d4a373', 
            color: 'white', 
            marginTop: '70px',
            padding: '10px 60px ',
            borderRadius: '30px',
            fontFamily: '"Poppins", sans-serif',
            fontWeight: '600',
            fontSize: '20px',
            fontStyle: 'normal',
            letterSpacing: '8px',
          }}
        >
          Katalog 2024
        </Button>
        </a>
      </Box>

      {/* Logo w prawym dolnym rogu */}
      <Box sx={{ position: 'absolute', bottom: '20px', right: '20px' }}>
        <img 
          src={`${process.env.PUBLIC_URL + '/sklep-z-prezentami.png'}`} 
          alt="Okazjonalny Sklep z Prezentami" 
          style={{ maxWidth: '450px', height: 'auto' }}
        />
      </Box>
    </Box>
  );
};

export default App;